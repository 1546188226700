/*
 * 업무구분: 재가입대상계약
 * 화 면 명: MSPPM850M (TSSPM063D)
 * 화면설명: 재가입대상계약
 * 접근권한: 모든사용자
 * 작 성 일: 2023.04.13
 * 작 성 자: 김진원
 */
<template>
  <ur-page-container class="msp" title="재가입대상계약" :show-title="true" type="subpage" action-type="search" :topButton="true" @action-search-click="fn_OpenMSPPM851P()" @on-header-left-click="fn_HistoryBack" :topButtonBottom="topButtonBottom">
     <!-- 고정영역 -->
    <template #frame-header-fixed>
      <!-- 검색결과 카테고리 출력 -->
      <ur-box-container alignV="start"  direction="column" class="search-result-box bd-T-Ty1 bd-b-Ty1">
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" @click="fn_OpenMSPPM851P">
            <span class="txt">{{targetMonth1}} ~ {{targetMonth2}}</span>
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
      <!-- 검색결과 카테고리 출력 //-->

      <!-- 총 명  -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title fexTy5">
              <span class="sum">총 <strong class="crTy-blue3">{{allItem.length}}</strong> 명</span>
              <span class="ns-tool-tip ml10 mt7">
                <mo-icon id="location0" icon="msp-tool-tip"></mo-icon>
                <mo-tooltip target-id="location0" class="tool-tip-box">
                  간편실손 재가입 대상 계약에 한하여 의사접수신청 가능합니다.
                </mo-tooltip>                  
              </span>  
            </div>
          </div>
          <!--div v-if="!$bizUtil.isEmpty(selectOrigin.applnrTt)" class="list-item__contents">
            <div class="list-item__contents__title fexTy5">
              <span class="sum"><strong class="crTy-blue3">1</strong>명 선택</span>
              <span class="ns-tool-tip ml10 mt7">
                <mo-icon id="location1" icon="msp-tool-tip"></mo-icon>
                <mo-tooltip target-id="location1" class="tool-tip-box">
                  간편실손의 재가입 대상 계약만 조회가능합니다.
                  (노후실손 재가입 대상은 추후제공예정)
                </mo-tooltip>                  
              </span>  
            </div>
          </div-->
        </mo-list-item>
      </ur-box-container>
      <!-- 총 명  //-->
    </template>
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap"> 
      <ur-box-container direction="column" alignV="start" class="ns-list-area msp-list-area pb150"> 
        <ur-box-container v-if="!isNoData" alignV="start" componentid="" direction="column" class="ns-radio-list2 type-2">
          <mo-list :list-data="allItem">
            <template #list-item="{ index, item}">
              <mo-list-item expandable prevent-expand  class="type-itm-2" :class="{'on' : item.selectApplnrTt}" @click.native.stop="fn_OnClickListItem(index)">
                <mo-radio v-model="item.selectApplnrTt" @input="fn_showCustomBottomsheet(item)" :ref="`chk${index}`"/>
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexTy3">
                    <span class="name txtSkip maxW100" @click.stop="fn_OpenCustInfoBs(item)">{{item.policyhoderNm}}</span>
                    <mo-badge class="badge-sample-badge sm" :class="item.renDcTypCdNm.indexOf('요청') != -1 ? 'lightgreen': 'lightred'" shape="status" v-if="item.renDcTypCdNm !== '-'">{{item.renDcTypCdNm}}</mo-badge> 
                  </div>
                  <div class="list-item__contents__info">
                    <span class="fs14rem mr10">피보험자</span><span class="crTy-bk1 txtSkip maxW70">{{item.insrdNmTt}}</span>
                    <span class="fexTy5-1" v-if="item.cvancFg === 'Y'"><em>|</em><span class="crTy-bk1 ml0">안내도달<b class="pl10 crTy-blue3">{{item.cvancFg}}</b></span></span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="fs14rem mr10">재가입예정일</span><span class="crTy-bk1">{{item.nxtREntDt}}</span>
                  </div>
                  <!-- 2023.01.31 수정 : 타이틀 말줄임(상품번호 있을 시) 유형 :  타이틀만 말줄임 상품번호 그대로 표출 -->
                  <div class="list-item__contents__title fwn linkTy">
                    <span class="tit fs16rem txtSkip" @click.stop="fn_getDecryptCont(item.applnrTt)">{{item.prdtNm}}</span>
                    <span class="prd-num fs16rem pl3" @click.stop="fn_getDecryptCont(item.applnrTt)">({{item.markUpContNo}})</span>
                  </div>
                  <!-- 2023.01.31 수정 : 타이틀 말줄임(상품번호 있을 시) 유형 :  타이틀만 말줄임 상품번호 그대로 표출 //-->
                </div>
                <!-- 아코디언 컨텐츠 -->
                <template v-slot:expand>
                  <div class="list-item-detail">
                    <div class="expand-inner-info-wrap">
                      <div class="contents-row">
                        <span class="fs14rem min100 mr20">계약상태</span><span class="crTy-bk1">{{item.actinactstNm}}</span>
                      </div>
                      <div class="contents-row">
                        <span class="fs14rem min100 mr20">납입상태</span><span class="crTy-bk1">{{item.lstatusNm}}</span>
                      </div>
                      <div class="contents-row">
                        <span class="fs14rem min100 mr20">계약일자</span><span class="crTy-bk1">{{item.polprodbegDt}}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>   
        </ur-box-container>

        <!-- NoData 영역 start -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_008" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- NoData 영역 end -->

      </ur-box-container>
    </ur-box-container>

    <!-- 바텀시트1 : 고객 선택시 재가입의사확인 버튼 노출  //-->
    <div class="ns-bottom-sheet">
      <mo-bottom-sheet ref="bottomNaviMenu" :close-btn="false" :class="positionUp? 'ns-bottom-sheet ns-style3 only-btn positionUp': 'ns-bottom-sheet ns-style3 only-btn'" noHeader>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs pal2430 noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_rEntTargetClick" class="bgcolor-2 bd-radius-6 blue">재가입의사확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
    </div>

    <!-- 바텀시트2 : 재가입의사확인 버튼 클릭시 노출  -->
    <mo-bottom-sheet ref="bottomSheet1" class="ns-bottom-sheet closebtn">
      <div class="ns-btn-close" @click="fn_closeBottomSheet1"></div>
      <div class="content-area txt-center mhAuto pb20">
        <p class="ment">
          {{ reqRentTargetMsg }}
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_getDecryptContKakao" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- 바텀시트2 : 재가입의사확인 버튼 클릭시 노출 //-->

    <!-- 바텀시트3 : 재가입의사확인 버튼 클릭시 노후실손일 경우 노출  -->
    <mo-bottom-sheet ref="bottomSheet2" class="ns-bottom-sheet closebtn">
      <div class="ns-btn-close" @click="fn_closeBottomSheet2"></div>
      <div class="content-area txt-center mhAuto pb20">
        <p class="ment">
          노후실손 재가입의사접수는 지점 또는 콜센터로 처리 바랍니다.
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_closeBottomSheet2" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- 바텀시트3 : 재가입의사확인 버튼 클릭시 노후실손일 경우 노출 //-->
    <!-- 컨텐츠영역  //-->

    <!-- 계약 로딩 표시 -->
    <div class="msp loading--dim msp-in-page" v-show="isReading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>
  </ur-page-container>
</template>
<script>
import MSPPM851P from '@/ui/pm/MSPPM851P' // 검색일자
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import pmConstants from '@/config/constants/pmConstants'
import Screen from '~systems/mixin/screen'
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPPM850M",
  screenId: "MSPPM850M",
  mixins: [Screen],
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  mounted() {
    let nowDate = moment(new Date()).format('YYYYMMDD')
    this.targetMonth1 = this.$bizUtil.getFirstDayOfMonth(new Date(moment(nowDate)), 'String'),
    this.targetMonth2 = this.$bizUtil.getLastDayOfMonth(new Date(moment(this.targetMonth1).add(+2, 'months')), 'String')
    this.fn_getREntObjrCont()
  },

  beforeDestroy() {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isTest: false,
      positionUp: false,
      stndKeyVal: 'start',
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],
      targetMonth1: '',
      targetMonth2: '',
      isemptyView: false, //데이터 존재 여부
      allItem: [],
      reqRentTargetMsg: '', //재가입의사 메시지
      selectOrigin: {}, //선택
      selectApplnrTt: '',
      isReading: false,
      loadingMsg: '고객 정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.',
      isNoData: false,

    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /**************************************************************************************
     * @function fn_HistoryBack
     * @notice   디바이스 뒤로가기 버튼
    **************************************************************************************/
    fn_HistoryBack () {
      if (this.selectOrigin.selectApplnrTt) {
        this.fn_confirm()
        return 
      }
      
      // 메뉴로 진입했으면 홈화면으로 이동한다.
      if (this.$route.query?._isMenuItem) {
        this.$router.push({name: 'MSPBC002M'})
        return
      }

      this.$router.go(-1)

      // 23-05-23 체크 시 back 버튼 동작 통일 김미정 프로
      /*
      if( this.positionUp ){
        // 바텀시트 초기화
        this.selectOrigin.selectApplnrTt = false
        this.fn_BottomHidden()

      } else {
        // 조회를 하지 않았으면 뒤로가기
        this.$router.go(-1)
      }
      */
    },

    /**
     * @description 고객카드
     */
    fn_showCustomBottomsheet(item) {
      if( item.selectApplnrTt ){
        if( this.selectOrigin.applnrTt !== item.applnrTt ){
          this.selectOrigin.selectApplnrTt = false
        }

        this.selectOrigin = item
        // 사용자 바텀시트 오픈
        // this.$refs.bottomNaviMenu.open() // [2024-11-05 재가입의사확인 버튼숨김]
        this.selectOrigin.contNo = item.applnrTt
        this.selectOrigin.contNoEnc = item.applnrTt
        let tmpList = [this.selectOrigin]
        tmpList.busnCd = 'PM'

        this.positionUp = true
        this.$cmUtil.setSelectedCustList(this, tmpList)

      } else {
        this.fn_BottomHidden()
      }
    },


    /**
     * @description 바텀 공통 메뉴바 원복
     */
    fn_BottomHidden(){
        this.positionUp = false
        this.$cmUtil.setSelectedCustList(this, [])
        this.$bizUtil.showCustomBottomSheet(this, this.$refs.bottomNaviMenu, false)

    },

    /**
     * @name fn_OpenMSPPM851P
     * @description 검색일자
     */
    fn_OpenMSPPM851P() {
      let lv_Vm = this
      this.popupMSPPM851P = this.$bottomModal.open(MSPPM851P, {
        properties: {
          targetMonth1: this.targetMonth1,
          targetMonth2: this.targetMonth2,
        },
        listeners: {
          onPopupClose: (startDt, endDt) => {
            this.targetMonth1 = startDt
            this.targetMonth2 = endDt
            this.fn_SearchList()
            console.log('startDt, endDt : ', startDt, endDt)
            lv_Vm.$bottomModal.close(lv_Vm.popupMSPPM851P)
          }
        }
      })
    },


    fn_SearchList(){
      this.stndKeyVal = 'start'
      this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}]
      this.allItem = []
      this.selectOrigin = {}
      this.isNoData = false 
      this.fn_BottomHidden()
      this.fn_getREntObjrCont()

    },

    /******************************************************************************
    * Function명 : fn_getREntObjrCont
    * 설명       : 데이터를 가져오기 위해 파라미터를 설정하는 부분
    ******************************************************************************/
    fn_getREntObjrCont() {
      if (this.stndKeyVal === 'end') return

      const userInfo = this.getStore('userInfo').getters.getUserInfo
      const fromDate = moment(new Date(this.targetMonth1)).format('YYYYMMDD')
      const toDate = moment(new Date(this.targetMonth2)).format('YYYYMMDD')
      const rnetScCd = pmConstants.RNET_SC_CD.ALL // 전체

      console.log('fn_getREntObjrCont : ', fromDate, toDate)

      let params = {
        rnetDtFr: fromDate,
        rnetDtTo: toDate,
        fofOrgNoId: userInfo.onpstFofOrgNo,
        userSnoId: userInfo.userId,
        rnetScCd: rnetScCd,
      }

      var lv_Vm = this
      this.post(lv_Vm, params, 'txTSSPM04P3', 'S')
        .then(function (response) {

          if (response.body) {
            lv_Vm.isemptyView = false // 데이터가 존재 하지 않습니다.
            let responseList = response.body.pmfprt0031VO
            responseList.forEach((item) => {
              item.selectApplnrTt = false
              item.markUpContNo = '*' + item.markUpContNo.substr(item.markUpContNo.length - 4) // 계약번호 마스킹
              item.nxtREntDt = moment(item.nxtREntDt).format('YYYY-MM-DD') // 재가입예정
              item.polprodbegDt = moment(item.polprodbegDt).format('YYYY-MM-DD') // 가입일자
              if (!item.renDcTypCdNm.trim()) { // 재가입유형
                item.renDcTypCdNm = '-'
              }
              if (!item.cvancFg.trim()) { // 안내도달여부
                item.cvancFg = '-'
              }
              lv_Vm.allItem.push(item)
            })

            console.log('data list : ', lv_Vm.allItem)

            lv_Vm.stndKeyList = response.trnstComm.stndKeyList
            if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              // 다음페이지 데이터 존재안함(마지막 페이지)
              lv_Vm.stndKeyVal = 'end'
            } else {
              // 다음페이지 데이터 존재함
              lv_Vm.stndKeyVal = 'more'
            }

            // 데이터 여부 
            if(lv_Vm.allItem.length === 0) this.isNoData = true 

          } else {
            lv_Vm.stndKeyVal = 'end'
            lv_Vm.isemptyView = true // 검색결과가 존재하지 않습니다.
            lv_Vm.isNoData = true 
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          if (!_.isEmpty(error.message)) {
            lv_Vm.getStore('confirm').dispatch('ADD', error.message)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.')
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_VpiREntTargetClick
     * 설명       : 재가입의사확인
     ******************************************************************************/
    fn_rEntTargetClick() {
      let lv_Vm = this

      let checkRentDt = moment(this.selectOrigin.nxtREntDt).format('YYYYMMDD') // 재가입일자
      let nowDate = moment(new Date()).format('YYYYMMDD')
      let checkCelno = this.fn_FullPhoneDashFormat(this.selectOrigin.mobilePh) // 핸드폰번호 마스킹
      let checkPolicyhoderNm = this.selectOrigin.policyhoderNm // 계약자
      let checkCnsntYn = this.selectOrigin.cnsntYn // 수신동의
      let checkPgYn = this.selectOrigin.pgYn // 단체
      let checkMinorYn = this.selectOrigin.miorYn // 미성년자
      let checkZzrEtPdTypCd = this.selectOrigin.zzrEtPdTypCd // 재가입상품유형코드

      if (checkZzrEtPdTypCd === pmConstants.ZZR_ET_PD_TYP_CD.OLDAG) { // 노후실손
        this.$refs.bottomSheet2.open();
      } else if (checkZzrEtPdTypCd === pmConstants.ZZR_ET_PD_TYP_CD.SIMP) { // 간편실손
        if ( checkMinorYn === 'Y') {
          lv_Vm.getStore('confirm').dispatch('ADD', '미성년자 계약 건으로 모바일 처리 불가합니다.')
        } else if ( checkPgYn === 'Y' ) {
          lv_Vm.getStore('confirm').dispatch('ADD', '단체 계약 건으로 모바일 처리 불가합니다.')
        } else if ( checkCnsntYn === 'N' ) {
          lv_Vm.getStore('confirm').dispatch('ADD', '휴대폰 오류(미등록 /비정상) 또는\n 문자수신 미동의 고객으로\n 모바일 처리 불가합니다.')
        } else if ( checkRentDt <= nowDate ) { // 재가입예정일 <= today
          lv_Vm.getStore('confirm').dispatch('ADD', '재가입예정일 도래건으로\n 재가입의사확인 처리가 불가합니다.')
        } else if ( checkRentDt > nowDate ) { // 재가입예정일 > To-day
          this.reqRentTargetMsg = checkPolicyhoderNm + ' 고객님 (' + checkCelno + ')께\n 재가입의사확인 요청을\n발송하시겠습니까?'
          this.$refs.bottomSheet1.open();
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_closeBottomSheet1
     * 설명       : bottomSheet1 닫기
     ******************************************************************************/
    fn_closeBottomSheet1() {
      this.$refs.bottomSheet1.close();
    },

    /******************************************************************************
     * Function명 : fn_closeBottomSheet2
     * 설명       : bottomSheet2 닫기
     ******************************************************************************/
    fn_closeBottomSheet2() {
      this.$refs.bottomSheet2.close();
    },

    /*********************************************************
     * Function명: fn_FullPhoneDashFormat
     * 설명: 받은 데이터 이용 전화번호 포멧 만드는 펑션
     * Params: N/A
     * Return: N/A
    *********************************************************/
    fn_FullPhoneDashFormat (s) {
      s = s.toString().replace(/-/gi, '')
      s = s.toString().replace(/ /gi, '')
      if (s || s.length > 0) {
        if (s.length > 11) {
          s = s.substr(0, 11)
        }
        if (s.length === 11) {
          return this.fn_StringDash(s, 3, 7)
        } else if (s.length >= 9) {
          return this.fn_StringDash(s, 3, 6)
        } else {
          return this.fn_StringDash(s, 3)
        }
      } else {
        return ''
      }
    },
    /*********************************************************
     * Function명: fn_StringDash
     * 설명: 인덱스로 Dash 스트링값 만드는 펑션
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_StringDash (targetString, index1, index2) {
      if (!index1 || index1 < 0 || index1 > targetString.length) {
        return targetString
      }
      if (index2) {
        if (!index2 || index2 < 0 || index2 > targetString.length) {
          return targetString
        }
        if (index2 <= index1) {
          return targetString
        }
        return targetString.slice(0, index1) + '-' + targetString.slice(index1, index2) + '-' + '****'
      }
      return targetString.slice(0, index1) + '-' + targetString.slice(index1, targetString.length)
    },
    /******************************************************************************
    * Function명 : fn_SendKaKaoIapTalk
    * 설명       : 카카오톡 알림 보내기
    * input : 계약번호, 고객 ID, 상품명
    ******************************************************************************/
    fn_SendKaKaoIapTalk(contNo) {
      this.$refs.bottomSheet1.close();

      let params = {
        contNo: contNo // 계약번호
      }

      let lv_Vm = this
      this.post(lv_Vm, params, 'txTSSPM04P2', 'S')
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {

            let responseResult = response.body
            if (responseResult.kakaoReturn === 'Y') {
              lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 정상적으로 발송했습니다')

            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 전송 실패했습니다')

            }

            lv_Vm.fn_SearchList()
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_ContNoClick
      * 설명       : 계약기본사항페이지로 이동하는 라우터
    ******************************************************************************/
    fn_ContNoClick(contNo) {
      
      let cnsltNo     = this.getStore('userInfo').getters.getUserInfo.userId
      
      let contInfoObj = {
        viewId: null,                         
        callerId: this.$options.screenId,     
        chnlCustId: null,               
        cnsltNo: cnsltNo,               
        contNo: contNo,                       
      }

      this.$router.push({name: 'MSPPM931M', params: contInfoObj})
      
    },
    /******************************************************************************
      * Function명 : fn_getDecryptCont
      * 설명       : 계약번호 복호화 
    ******************************************************************************/
    async fn_getDecryptCont(_string) {
      let data = [_string]
      let enData = await this.$commonUtil.getDecryptCall(this,data)
      this.fn_ContNoClick(enData[0])
    },

    async fn_getDecryptContKakao(){
      let enData = await this.$commonUtil.getDecryptCall(this, [this.selectOrigin.applnrTt])
      this.fn_SendKaKaoIapTalk(enData[0])
    },

    /******************************************************************************
      * Function명  : fn_SetProgressMsgPop
      * 설명        : 로딩 메시지 설정
      * Params      : pShowFlag - Progress 화면 설정 Flag
      ******************************************************************************/
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },
    /******************************************************************************
      * Function명 : fn_OpenCustInfoBs
      * 설명       : 고객명을 클릭할 때 nameUI를 호출한다.
    ******************************************************************************/
    async fn_OpenCustInfoBs(item){
      // 복호화된 계약번호 
      let prcContNo = await this.$commonUtil.getDecryptCall(this, [item.applnrTt]) 
          prcContNo = prcContNo[0]
      let cnsltNo    = this.getStore('userInfo').getters.getUserInfo.userId,
          nmUiObject = {}

      nmUiObject = {
        chnlCustId: item.chnlCustId,
        custNm: item.custNm,
        cnsltNo: cnsltNo,
        contNo : prcContNo,
        parentId: this.$options.screenId
      }

      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, function (response){
        return
      })

    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              // 메뉴로 진입했으면 홈화면으로 이동한다.
              if (this.$route.query?._isMenuItem) {
                this.$router.push({name: 'MSPBC002M'})
                return
              }
              this.$router.go(-1)
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /**
     * @description 체크박스 이외의 영역 클릭했을 때, 체크 활성화 및 비활성화 한다.
     * @param       {Number} index 리스트 데이터의 index
     */
    fn_OnClickListItem(index) {
      this.$refs[`chk${index}`].onClick()
    },


  }
};
</script>
<style scoped>
</style>